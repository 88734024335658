import React from 'react'
import Cartaz from '../../images/cartaz-graduacao.png'

const AnunciosGraduacao = () => {
  return(
    <div 
    className="flex flex-col w-full h-screen" 
    style={{justifyContent: "center", alignItems: "center"}}>
        <div 
        className="flex flex-col  w-52 h-80 rounded shadow-lg" 
        style={{justifyContent: "center", alignItems: "center"}}>
            <img src={Cartaz} alt="Cartaz Eja" className="object-contain overflow-hidden rounded"/>
        </div>
    </div>
  )
}

export default AnunciosGraduacao