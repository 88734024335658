import React from "react"
import Layout from "../components/Layout"
import Graduacao from "../components/Inscricoes/graduacao"
import AnuncioGraduacao from "../components/Anuncios/graduacao"

import { LeftWrapper, RightWrapper } from "../components/Layout/styled"

import SEO from "../components/seo"

const GraduacaoPage = () => (
  <Layout>
    <SEO title="Inscrição Graduação" />
      <LeftWrapper>
        <Graduacao />
      </LeftWrapper>
      <RightWrapper>
        <AnuncioGraduacao />
      </RightWrapper>
  </Layout>
)

export default GraduacaoPage
